import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { aboutContainer, hours, hoursList, aboutIllus, aboutInfo, buyInfo } from './about.module.css'
import { graphql } from "gatsby"

const BlockContent = require('@sanity/block-content-to-react')

export default function AboutPage({ data: { allSanitySiteSettings }}) {

  return (
    <Layout containerStyle="desktopLanding">
      <Seo title={"About"} />
      <div className={aboutContainer}>
        <div className={hours}>
          <img src="/hours-header.svg" alt="Text: Our Hours Are" />
          <dl className={hoursList}>
            <div>
              <dt>Monday</dt>
              <dd>{allSanitySiteSettings.edges[0].node.mondayHours}</dd>              
            </div>
            <div>
              <dt>Tuesday - Friday</dt>
              <dd>{allSanitySiteSettings.edges[0].node.tuesFriHours}</dd>
            </div>
            <div>
              <dt>Saturday</dt>
              <dd>{allSanitySiteSettings.edges[0].node.satHours}</dd>
            </div>
            <div>
              <dt>Sunday</dt>
              <dd>{allSanitySiteSettings.edges[0].node.sunHours}</dd>
            </div>
          </dl>
        </div>  
        <div className={aboutInfo}> 
        <BlockContent blocks={allSanitySiteSettings.edges[0].node._rawAbout} />  
        </div>   
        <img class={aboutIllus} src="/one-thing-leads-to-another.svg" alt="Diagram of peace sign turning into pickle into heart into book with caption 'One Thing Leads to Another'" />
        <div className={buyInfo}> 
        <BlockContent blocks={allSanitySiteSettings.edges[0].node._rawBuyInfo} />  
        </div> 
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allSanitySiteSettings {
      edges {
        node {       
          mondayHours
          tuesFriHours
          satHours
          sunHours
          _rawAbout
          _rawBuyInfo
        }
      }
    }
  }
`
